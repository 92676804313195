
import { defineComponent, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
import { useStructures, useTenant, useContents } from '@/core'
import { StructuresNode } from 'types/interface/StructuresNode'

import Topbar from '@/components/Topbar.vue'
import TheHeading from '@/components/TheHeading.vue'
import ContentRenderer from '@/components/ContentRenderer.vue'

export default defineComponent({
  name: 'Single',

  components: {
    Topbar,
    TheHeading,
    ContentRenderer,
  },

  setup() {
    const { tenant } = useTenant()
    const route = useRoute()
    const { fetchObject } = useContents()
    const {
      getStructuresBySlugPath,
      getStructuresChildrens,
      fetchStructuresNode,
      fetchStructuresChildren,
    } = useStructures()

    const parentSlug = computed(() => {
      const { slugPath } = route.params
      if (!Array.isArray(slugPath)) return slugPath

      let path = `${slugPath.slice(0, -1).join('/')}`

      return path
    })

    const contentSlug = computed(
      () =>
        `${
          route.params.slugPath && (route.params.slugPath as string[]).join('/')
        }`,
    )

    const content = computed(() => {
      return getStructuresBySlugPath(contentSlug.value)
    })

    const contentItems = computed(() => {
      const contentTree = getStructuresChildrens(content.value.id).map(
        (child: StructuresNode) => {
          if (child.type === 'DIRECTORY') {
            child.contentData = getStructuresChildrens(child.id)
            child.contentType = 'section'
          }
          return child
        },
      )
      return contentTree
    })

    useHead({
      title: computed(() => `${content.value.name} - ${tenant.value.name}`),
    })

    onMounted(async () => {
      await fetchStructuresNode(contentSlug.value)
      await fetchStructuresChildren(contentSlug.value, { limit: 100 })
      await fetchStructuresNode(parentSlug.value)

      await Promise.all(
        contentItems.value.map(async (ci: StructuresNode) => {
          if (ci.type === 'DIRECTORY') {
            await fetchStructuresChildren(ci.id, { limit: 100 })
          }
          if (ci.contentId) {
            await fetchObject(ci.contentId)
          }
          return ci
        }),
      )
      contentItems.value.map((pi: StructuresNode) => {
        if (pi.type === 'DIRECTORY') {
          pi.contentData.forEach((ci: StructuresNode) => {
            if (ci.contentId) {
              fetchObject(ci.contentId)
            }
          })
        }
        return pi
      })
    })

    return {
      content,
      contentItems,
      contentSlug,
    }
  },
})
